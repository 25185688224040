.bg-pink-blue{
  /*background: linear-gradient(140deg, #BC70A4 20%, #1fc8db 100%);*/
  background: rgb(255, 204, 30);
  background: linear-gradient(90deg, rgba(175,104,203,1) 0%, rgba(228,50,50,1) 50%, rgba(252,210,69,1) 100%);
}
.bg-projects{
  background-color: #0dcaf0;
  background-image: url('./projects_bg.jpg');
  background-repeat: repeat;
}
.bg-skills{
  background-color: #198754;
  background-image: url('./green_bg.jpg');
  background-repeat: repeat;
}
.bg-school-work{
  background: #0E6FFD;
  background: -webkit-linear-gradient(top left, #0E6FFD, #DC3645);
  background: -moz-linear-gradient(top left, #0E6FFD, #DC3645);
  background: linear-gradient(to bottom right, #0E6FFD, #DC3645);
}
.F-Great-Vibes{
  font-family: 'Great Vibes', sans-serif;
}
.F-abel{
  font-family: 'Abel', sans-serif;
}
.Portrait{
  max-width: 300px !important;
  max-height: 300px !important;
}